const WORKSTATIONS_USER = 'WORKSTATIONS_USER';
const WORKSTATIONS_TOKEN = 'WORKSTATIONS_TOKEN';

export const setUser = (user) => {
	localStorage.setItem(WORKSTATIONS_USER, JSON.stringify(user));
};

export const setToken = (token) => {
	localStorage.setItem(WORKSTATIONS_TOKEN, token);
};

export const getUser = () => {
	const user = localStorage.getItem(WORKSTATIONS_USER);
	if (user) return JSON.parse(user);
	return null;
};

export const getToken = () => {
	const token = localStorage.getItem(WORKSTATIONS_TOKEN);
	if (token) return token;
	return null;
};

export const removeUser = () => {
	localStorage.clear();
};

export const filterByWindowItems = (orders, orderId) => {
	let result = [];
	for (let order of orders) {
		if (order[0].OrderID === orderId) {
			let processWindowItems = {};
			for (let orderDetails of order) {
				if (Object.keys(processWindowItems).some((key) => orderDetails.ProcessWindowItem.id === key)) {
					processWindowItems[orderDetails.ProcessWindowItem.id].push(orderDetails);
				} else {
					processWindowItems[orderDetails.ProcessWindowItem.id] = [];
					processWindowItems[orderDetails.ProcessWindowItem.id].push(orderDetails);
				}
			}
			result.push(processWindowItems);
		}
	}
	return result;
};

export function filterProcessStatusBaseFunction(pendingOrders, id) {
	//* row is order
	let finishingCount = 0;
	let pendingCount = 0;
	let inProcessingCount = 0;
	for (let i of pendingOrders) {
		for (let j of i) {
			if (j.OrderID === id) {
				switch (j.process_status) {
					case 'finished': {
						finishingCount = finishingCount + 1;
						break;
					}
					case 'InProcessing': {
						inProcessingCount = inProcessingCount + 1;
						break;
					}
					default: {
						pendingCount = pendingCount + 1;
					}
				}
			}
		}
	}
	return [finishingCount, pendingCount, inProcessingCount];
}

export const filterSheetProcesses = (orders, workstation) => {
	const sheetData = workstation?.sheet_data;
	try {
		for (let order of orders) {
			for (let i of order) {
				let result = [];
				for (let sheet of i.sheetProcesses) {
					for (let sheet1 of sheetData) {
						if (sheet1.name === sheet.sheet_name) {
							result.push(sheet);
						}
					}
					i.sheetProcesses = result;
				}
			}
		}
	} catch (error) {
		alert('Something wrong happened!');
	}
	return orders;
};
