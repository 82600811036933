import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


export default function CustomAlert({ message, setOpen }) {
	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={true}
				message={message}
				autoHideDuration={4000}
				key={{ vertical: 'bottom', horizontal: 'center' }}
				action={
					<React.Fragment>
						<IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen({
							status: false
						})}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			/>
		</div>
	);
}
