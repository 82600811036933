import React from 'react';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

export default function Remove() {
	return (
		<div>
			<IndeterminateCheckBoxIcon style={{ fill: 'red', cursor: 'pointer', fontSize: '6.5vh' }} />
		</div>
	);
}
