export const SET_WORKSTATIONS = 'SET_WORKSTATIONS';
export const SET_WORKSTATION = 'SET_WORKSTATION';

export const workstationState = {
	workstations: [],
	selectedWorkstation: {},
};

export const workstationReducer = (state = workstationState, action) => {
	const type = action.type;
	switch (type) {
		case SET_WORKSTATIONS: {
			return {
				...state,
				workstations: action.payload,
			};
		}
		default: {
			return {
				...state,
				selectedWorkstation: action.payload,
			};
		}
	}
};
