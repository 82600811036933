import React, { useContext, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { removeUser } from '../../app/commonFunctions';
import { UserContext } from '../../app/context';
import { REMOVE_USER } from '../../app/reducers/user-reducer';
import WorkStations from '../drop-down/WorkStations';
import { logout } from '../../app/server/services';
import Backdrop from '../backdrop/Backdrop';
import Alert from '../alert/Alert';

const useStyles = makeStyles((theme) => ({
	menuButton: {},
	headerItem: {
		color: theme.palette.secondary.main,
		fontSize: '16px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.primary.main,
		},
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	firstItem: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '10px',
	},
	logo: {
		width: '60px',
		height: '60px',
		cursor: 'pointer',
	},
	typography: {
		cursor: 'pointer',
		marginLeft: theme.spacing(2),
		color: theme.palette.secondary.main,
	},
	divider: {
		margin: '0px 20px',
	},
	icon: {
		marginRight: '10px',
		fill: theme.palette.common.black,
		cursor: 'pointer',
		'&:hover': {
			borderRadius: '3px',
			boxShadow: '1px 1px 3px black',
		},
	},
	listItemIcon: {
		marginRight: '-20px',
	},
	navLink: {
		textDecoration: 'none',
		color: theme.palette.secondary.main,
	},
}));

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		padding: '0px 2px',
	},
})((props) => (
	<Menu
		elevation={2}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		maxWidth: 250,
		minWidth: 250,
	},
}))(MenuItem);

const StyledMenuComponent = () => {
	const classes = useStyles();
	const history = useHistory();
	const [backdrop, setBackdrop] = useState(false);
	const [alert, setAlert] = useState({
		status: false,
		message: '',
	});
	const { dispatch, token } = useContext(UserContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClick = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
		handleMenu();
		setBackdrop(true);
		const res = await logout(token);
		setBackdrop(false);
		if (res) {
			removeUser();
			dispatch({ type: REMOVE_USER });
			history.push('/login');
			return;
		}
		setAlert({
			status: true,
			message: 'Something wrong happened!',
		});
	};

	return (
		<>
			{backdrop ? <Backdrop /> : null}
			{alert.status ? <Alert message={alert.message} /> : null}
			<IconButton edge="start" aria-label="menu" onClick={handleMenu}>
				<MenuIcon className={classes.menuButton} />
			</IconButton>
			<StyledMenu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={() => setAnchorEl(null)}
			>
				<div className={classes.firstItem}>
					<Clear className={classes.icon} onClick={handleMenuClick} />
				</div>
				<StyledMenuItem>
					<WorkStations />
				</StyledMenuItem>
				<StyledMenuItem onClick={handleLogout}>
					<ListItemIcon style={{ marginRight: '-25px' }}>
						<ExitToAppIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</StyledMenuItem>
			</StyledMenu>
		</>
	);
};

export default StyledMenuComponent;
