import React, { useContext, useEffect, useState } from 'react';
import '../../../assets/css/Style.css';
import Plus from '../../icons/Plus';
import Remove from '../../icons/Remove';
import Block from '../../icons/Block';
import GreenBlock from '../../icons/GreenBlock';
import { PendingOrdersContext, CompletedOrdersContext, UserContext, SubProcessCount } from '../../../app/context';
import { filterByWindowItems } from '../../../app/commonFunctions';
import { DECREASE_OPTION, INCREASE_OPTION } from '../../tabs/sheets-tabs/SheetsTabs';
import { updateProcessStatus, updateParentProcessStatus } from '../../../app/server/services';
import Backdrop from '../../backdrop/Backdrop';
import Alert from '../../alert/Alert';
import { SET_ORDERS } from '../../../app/reducers/pending-orders-reducer';
import { screen } from '../../tabs/sheets-tabs/SheetsTabs';
import { SET_PROCESSES, UPDATE_PROCESS_REDUCER } from '../../../app/reducers/sub-processes-count';
import logoutHook from '../../../app/hooks/Logout';

function Row({ row, orderStatus }) {
	const { token } = useContext(UserContext);
	const { orders, dispatch } = useContext(PendingOrdersContext);
	const [states, setStates] = useState(orderStatus);
	const subProcessesDispatch = useContext(SubProcessCount).dispatch;

	const [backdrop, setBackdrop] = useState(false);
	const [alert, setAlert] = useState({
		status: false,
		message: 'Something wrong happened!',
	});
	let finishingCount = 0;
	let inProcessingCount = 0;
	let pendingCount = 0;

	if (orderStatus) {
		for (let j of states) {
			switch (j.sheet.status) {
				case 'finished': {
					finishingCount++;
					break;
				}
				case 'InProcessing': {
					inProcessingCount++;
					break;
				}
				default: {
					pendingCount++;
					break;
				}
			}
		}
	}

	const updateIncreaseData = (newData) => {
		setStates(newData);
		dispatch({ type: SET_ORDERS, payload: orders });
		subProcessesDispatch({
			type: UPDATE_PROCESS_REDUCER,
			payload: {
				type: INCREASE_OPTION,
			},
		});
	};

	const updateDecreaseData = (newData) => {
		setStates(newData);
		dispatch({ type: SET_ORDERS, payload: orders });
		subProcessesDispatch({
			type: UPDATE_PROCESS_REDUCER,
			payload: {
				type: DECREASE_OPTION,
			},
		});
	};

	const checkFinishedParentProcessStatus = async (orderDetails, parentProcessId) => {
		let finishedProcessesCount = 0;
		for (let i of orderDetails.sheetProcesses) {
			if (i.status === 'finished') finishedProcessesCount++;
		}
		if (finishedProcessesCount === orderDetails?.sheetProcesses?.length) {
			orderDetails.process_status = 'finished';
			const res = await updateParentProcessStatus(parentProcessId, 'finished', token);
			setBackdrop(false);
			switch (res.code) {
				case 200: {
					return 'response';
				}
				case 401: {
					handleLogout();
					return null;
				}
				default: {
					setAlert((prevState) => ({
						...prevState,
						status: true,
					}));
					return null;
				}
			}
		}
		return 'response';
	};

	const checkInProcessingParentProcessStatus = async (orderDetails, parentProcessId) => {
		let inProcessingProcessesCount = 0;
		for (let i of orderDetails.sheetProcesses) {
			if (i.status === 'InProcessing') inProcessingProcessesCount++;
		}
		if (inProcessingProcessesCount <= orderDetails?.sheetProcesses?.length) {
			orderDetails.process_status = 'InProcessing';
			const res = await updateParentProcessStatus(parentProcessId, 'InProcessing', token);
			setBackdrop(false);
			switch (res.code) {
				case 200: {
					return 'response';
				}
				case 401: {
					handleLogout();
					return null;
				}
				default: {
					setAlert((prevState) => ({
						...prevState,
						status: true,
					}));
					return null;
				}
			}
		}
		return 'response';
	};

	const { handleLogout } = logoutHook();

	const orderStates = `${finishingCount}/${finishingCount + inProcessingCount + pendingCount}`;
	const changeOrderStatus = async (type) => {
		setBackdrop(true);
		switch (type) {
			case INCREASE_OPTION: {
				const newData = [...states];
				const index = newData?.findIndex((sheetStatus) => sheetStatus.sheet.status === 'InProcessing');
				const data = {
					id: newData[index].sheet.id,
					status: 'finished',
				};
				const res = await updateProcessStatus(data, newData[index].sheet.id, token);
				switch (res.code) {
					case 200: {
						const parentProcessId = newData[index].parentProcessId;
						const id = newData[index].sheet.id;
						newData[index].sheet.status = 'finished';
						for (let order of orders) {
							for (let orderDetails of order) {
								if (parentProcessId === orderDetails.id) {
									for (let i of orderDetails.sheetProcesses) {
										if (i.id === id) {
											i.status = 'finished';
											break;
										}
									}
									const result = await checkFinishedParentProcessStatus(
										orderDetails,
										parentProcessId
									);
									if (result) updateIncreaseData(newData);
								}
							}
						}
						break;
					}
					case 401: {
						handleLogout();
						break;
					}
					default: {
						setAlert((prevState) => ({
							...prevState,
							status: true,
						}));
					}
				}
				setBackdrop(false);
				break;
			}
			default: {
				const newData = [...states];
				const index = newData?.findIndex((sheetStatus) => sheetStatus.sheet.status === 'finished');
				const data = {
					id: newData[index].sheet.id,
					status: 'InProcessing',
				};
				const res = await updateProcessStatus(data, newData[index].sheet.id, token);
				switch (res.code) {
					case 200: {
						const parentProcessId = newData[index].parentProcessId;
						const id = newData[index].sheet.id;
						newData[index].sheet.status = 'InProcessing';
						for (let order of orders) {
							for (let orderDetails of order) {
								if (parentProcessId === orderDetails.id) {
									for (let i of orderDetails.sheetProcesses) {
										if (i.id === id) {
											i.status = 'InProcessing';
											break;
										}
									}
									const result = await checkInProcessingParentProcessStatus(
										orderDetails,
										parentProcessId
									);
									if (result) updateDecreaseData(newData);
								}
							}
						}
						break;
					}
					case 401: {
						handleLogout();
						break;
					}
					default: {
						setAlert((prevState) => ({
							...prevState,
							status: true,
						}));
					}
				}
				setBackdrop(false);
				break;
			}
		}
	};

	return (
		<>
			{backdrop ? <Backdrop /> : null}
			{alert.status ? <Alert message={alert.message} setOpen={setAlert} /> : null}
			<tr>
				<td>{row?.tag}</td>
				<td>{row?.window_type}</td>
				<td>{row?.quantity}</td>
				<td>{row?.coil}</td>
				<td>
					<div>{row?.width}</div>
					<div>[{row?.width_fraction}]</div>
				</td>
				<td>
					<div>{row?.height}</div>
					<div>[{row?.height_fraction}]</div>
				</td>
				<td>{row?.window_color}</td>
				<td>
					{orderStatus ? (
						inProcessingCount > 0 || finishingCount > 0 ? (
							<div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
								{finishingCount === 0 ? (
									<Block />
								) : (
									<span onClick={() => changeOrderStatus(DECREASE_OPTION)}>
										<Remove />
									</span>
								)}
								{orderStates}
								{inProcessingCount === 0 ? (
									<GreenBlock />
								) : (
									<span onClick={() => changeOrderStatus(INCREASE_OPTION)}>
										<Plus />
									</span>
								)}
							</div>
						) : (
							<div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
								<Block /> {orderStates} <Block />
							</div>
						)
					) : (
						<Block />
					)}
				</td>
			</tr>
		</>
	);
}

function RowController({ row, orderStatus }) {
	const window = row.screen_sheet?.assembly;
	if (!window) return null;
	const result = [];
	if (window instanceof Array) {
		for (let item of window) {
			result.push(<Row key={(Math.random() * 100 + '').substring(0, 2)} row={item} orderStatus={orderStatus} />);
		}
		return result;
	}
	result.push(<Row key={(Math.random() * 100 + '').substring(0, 2)} row={window} orderStatus={orderStatus} />);
	return result;
}

export default function ScreenSheet({ orderId, completedOrder }) {
	const { orders } = useContext(PendingOrdersContext);
	const { completedOrders } = useContext(CompletedOrdersContext);
	const [filteredOrders, setOrders] = useState([]);
	const { dispatch } = useContext(SubProcessCount);

	const filterBySubProcessesStatus = (windowItems) => {
		let finishedCount = 0;
		let inProcessingCount = 0;
		for (let item of windowItems) {
			if (item instanceof Array) {
				for (let windowItem of item) {
					if (windowItem.sheet.status === 'InProcessing') inProcessingCount++;
					else if (windowItem.sheet.status === 'finished') finishedCount++;
				}
			} else {
				if (item.sheet.status === 'InProcessing') inProcessingCount++;
				else if (item.sheet.status === 'finished') finishedCount++;
			}
		}
		dispatch({
			type: SET_PROCESSES,
			payload: {
				inProcessingCount,
				finishedCount,
			},
		});
	};
	useEffect(() => {
		const showPendingOrders = () => {
			const result = filterByWindowItems(orders, orderId);
			for (let i of result) {
				if (Object.keys(i).length > 1) {
					let allResults = [];

					for (let j of Object.values(i)) {
						const result_ = [];
						for (let item of j) {
							if (item.sheetProcesses.length === 0) continue;
							for (let sheet of item.sheetProcesses) {
								if (sheet.sheet_name === screen) {
									result_.push({
										sheet,
										parentProcessId: item.id,
									});
								}
							}
						}
						if (result_.length === 0) continue;
						allResults.push(result_);

						setOrders((prevState) => [
							...prevState,
							<RowController
								key={(Math.random() * 100 + '').substring(0, 2)}
								row={j[0]}
								orderStatus={result_}
							/>,
						]);
					}
					filterBySubProcessesStatus(allResults);
				} else {
					const result_ = [];
					for (let item of Object.values(i)[0]) {
						for (let sheet of item.sheetProcesses) {
							if (sheet.sheet_name === screen) {
								result_.push({
									sheet,
									parentProcessId: item.id,
								});
							}
						}
					}
					if (result_.length !== 0) {
						filterBySubProcessesStatus(result_);
						setOrders((prevState) => [
							...prevState,
							<RowController
								key={(Math.random() * 100 + '').substring(0, 2)}
								row={Object.values(i)[0][0]}
								orderStatus={result_}
							/>,
						]);
					}
				}
			}
		};
		const showCompletedOrders = () => {
			for (let item of completedOrders) {
				setOrders((prevState) => [
					...prevState,
					<RowController key={(Math.random() * 100 + '').substring(0, 2)} row={item} />,
				]);
			}
		};
		if (!completedOrder) {
			showPendingOrders();
			return;
		}
		showCompletedOrders();
		// eslint-disable-next-line
	}, []);
	return (
		<div className="table-1">
			<table>
				<thead>
					<tr>
						<th>TAG</th>
						<th>TYPE</th>
						<th>QTY</th>
						<th>COIL SIZE</th>
						<th>WIDTH</th>
						<th>HEIGHT</th>
						<th>WINDOW COLOR</th>
						<th>CHECK</th>
					</tr>
				</thead>
				<tbody>{filteredOrders}</tbody>
			</table>
		</div>
	);
}
