export const SET_COM_ORDER = 'SET_COM_ORDER';

export const completedOrdersState = {
	completedOrder: [],
};

export const completedOrdersReducer = (state = completedOrdersState, action) => {
	if (action.type === SET_COM_ORDER) {
		return { completedOrders: action.payload };
	}
	return { state };
};
