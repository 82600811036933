import React from 'react';

export const UserContext = React.createContext({
	user: '',
	token: '',
	dispatch: () => {},
});

export const WorkstationContext = React.createContext({
	workstations: [],
	selectedWorkstation: {},
	dispatch: () => {},
});

export const PendingOrdersContext = React.createContext({
	orders: [],
	dispatch: () => {},
});

export const PendingOrderDetailsContext = React.createContext({
	orders: [],
	dispatch: () => {},
});

export const CompletedOrdersContext = React.createContext({
	completedOrder: [],
	dispatch: () => {},
});

export const SubProcessCount = React.createContext({
	finishedProcesses: 0,
	inProcessingProcesses: 0,
	dispatch: () => {},
});
