export const SET_ORDERS_DETAILS = 'SET_ORDERS_DETAILS';

export const pendingOrderDetailsState = {
	orders: [],
};

export const pendingOrderDetailsReducer = (state = pendingOrderDetailsState, action) => {
	const type = action.type;
	switch (type) {
		case SET_ORDERS_DETAILS: {
			return {
				orders: action.payload,
			};
		}
		default: {
			return {
				state,
			};
		}
	}
};
