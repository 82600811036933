import { INCREASE_OPTION, DECREASE_OPTION } from '../../components/tabs/sheets-tabs/SheetsTabs';
const SET_PROCESSES = 'SET_PROCESSES';
const UPDATE_PROCESS_REDUCER = 'UPDATE_PROCESS_REDUCER';

const subProcessesState = {
	finishedProcesses: 0,
	inProcessingProcesses: 0,
};

const subProcessesReducer = (state = subProcessesState, action) => {
	const type = action.type;
	switch (type) {
		case UPDATE_PROCESS_REDUCER: {
			const opType = action.payload.type;
			const data = { ...state };
			if (opType === INCREASE_OPTION) {
				data.finishedProcesses = ++data.finishedProcesses;
				data.inProcessingProcesses = --data.inProcessingProcesses;
			} else if (opType === DECREASE_OPTION) {
				data.inProcessingProcesses = ++data.inProcessingProcesses;
				data.finishedProcesses = --data.finishedProcesses;
			}
			return data;
		}
		case SET_PROCESSES: {
			return {
				finishedProcesses: action.payload.finishedCount,
				inProcessingProcesses: action.payload.inProcessingCount,
			};
		}
		default: {
			return { state };
		}
	}
};

export { SET_PROCESSES, UPDATE_PROCESS_REDUCER, subProcessesReducer, subProcessesState };
