import React from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';

export default function Plus() {
	return (
		<div>
			<AddBoxIcon style={{ fill: 'green', cursor: 'pointer', fontSize: '6.5vh' }} />
		</div>
	);
}
