import React from 'react';
import BlockIcon from '@material-ui/icons/Block';

export default function Block() {
	return (
		<div>
			<BlockIcon style={{ fill: 'red', cursor: 'pointer', fontSize: '6.5vh' }} />
		</div>
	);
}
