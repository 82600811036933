import React, { useState, useContext } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button } from '@material-ui/core';
import { WorkstationContext, UserContext, PendingOrdersContext, PendingOrderDetailsContext } from '../../app/context';
import { getProcesses, getOrders } from '../../app/server/services';
import { filterSheetProcesses } from '../../app/commonFunctions';
import { SET_ORDERS } from '../../app/reducers/pending-orders-reducer';
import { SET_ORDERS_DETAILS } from '../../app/reducers/pending-orders-details';
import Backdrop from '../backdrop/Backdrop';
import Alert from '../alert/Alert';
import logout from '../../app/hooks/Logout';
import { SET_WORKSTATION } from '../../app/reducers/workstation-reducer';

export default function WorkstationsMenu() {
	const [anchorEl, setAnchorEl] = useState(null);
	const { token } = useContext(UserContext);
	const { selectedWorkstation, workstations, dispatch } = useContext(WorkstationContext);
	const ordersDispatch = useContext(PendingOrdersContext).dispatch;
	const ordersDetailsDispatch = useContext(PendingOrderDetailsContext).dispatch;
	const [backdrop, setBackdrop] = useState(false);
	const { handleLogout } = logout();
	const [alert, setAlert] = useState({
		message: '',
		status: false,
	});

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = async (workstation) => {
		dispatch({ type: SET_WORKSTATION, payload: workstation });
		handleClose();
		setBackdrop(true);
		const res2 = await getProcesses(workstation.id, token);
		switch (res2.code) {
			case 401: {
				handleLogout();
				break;
			}
			case 200: {
				let convertedObjToArray = Object.values(res2.data);
				convertedObjToArray = filterSheetProcesses(convertedObjToArray, workstation);
				const ordersIDs = Object.keys(res2.data);
				let ordersDetails = [];
				for (let id of ordersIDs) {
					const res3 = await getOrders(id, token);
					switch (res3.code) {
						case 200: {
							ordersDetails.push(res3.data);
							break;
						}
						case 401: {
							handleLogout();
							break;
						}
						default: {
							setAlert({
								status: true,
								message: 'Something wrong happened!',
							});
							break;
						}
					}
				}
				ordersDetailsDispatch({ type: SET_ORDERS_DETAILS, payload: ordersDetails });
				ordersDispatch({ type: SET_ORDERS, payload: convertedObjToArray });
				setBackdrop(false);
				break;
			}
			default: {
				setAlert({
					status: true,
					message: 'Something wrong happened!',
				});
				setBackdrop(false);
			}
		}
	};

	return (
		<div>
			{backdrop ? <Backdrop /> : null}
			{alert.status ? <Alert message={alert.message} setOpen={setAlert} /> : null}
			<Button
				style={{
					fontWeight: 'bold',
					minHeight: '53px',
					fontSize: '2.5vh',
					textTransform: 'none',
				}}
				aria-controls="simple-menu"
				aria-haspopup="true"
				variant="text"
				onClick={handleClick}
				endIcon={<ArrowDropDownIcon />}
			>
				{selectedWorkstation.name}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				PaperProps={{
					style: {
						width: '23ch',
						marginTop: '50px',
					},
				}}
			>
				{workstations.map((workstation, index) => {
					return (
						<MenuItem key={index} onClick={() => handleMenuItemClick(workstation)}>
							{workstation.name}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
}
