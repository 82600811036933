import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';
import { Paper } from '@material-ui/core';
import CutSheetFrame from '../../tables/cut-sheet/Frame';
import FrameAccessories from '../../tables/cut-sheet/FrameAccessories';
import CutSheetSash from '../../tables/cut-sheet/Sash';
import ScreenSheet from '../../tables/screen-sheet/ScreenSheet';
import WeldingSheetFrames from '../../tables/welding-sheet/Frames';
import SashAccessories from '../../tables/cut-sheet/SashAccessories';
import WeldingSheetSash from '../../tables/welding-sheet/Sash';
import Coil from '../../tables/welding-sheet/Coil';
import Assembly from '../../tables/screen-sheet/Assembly';
import { SubProcessCount, WorkstationContext } from '../../../app/context';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		marginTop: '10px',
	},
	tab: {
		background: theme.palette.primary.main,
		fontWeight: 'bold',
		color: 'white',
		marginRight: '3px',
	},
	itemsFStatus: {
		background: theme.palette.primary.light,
		borderRadius: '5px',
		padding: '2px 6px',
		marginLeft: '10px',
	},
	itemPStatus: {
		background: theme.palette.success.light,
		color: theme.palette.success.main,
		borderRadius: '5px',
		marginBottom: '2px',
		marginLeft: '10px',
		padding: '2px 6px',
	},
}));

const INCREASE_OPTION = 'INCREASE_OPTION';
const DECREASE_OPTION = 'DECREASE_OPTION';

const screen = 'Screen';
const assembly = 'Assembly';
const frameAccessories = 'Cut_Frame_Accessories'; // i will receive it with name Frame_accessories
const sashAccessories = 'Cut_Sash_Accessories'; // i will receive it with name Sash_accessories
const cutSheetSash = 'Cut_Sash'; // i will receive it with name Frame_sash
const cutSheetFrame = 'Cut_Frame'; // i will receive it with name Frame_sash
const weldingSheetSash = 'Welding_Sash'; // i will receive it with name Sash
const weldingSheetFrames = 'Welding_Frame'; // i will receive it with name Frame
const weldingCoil = 'Welding_Coil'; // i will receive it with name Frame

const tabs = [
	{
		isShown: false,
		name: screen,
	},
	{
		isShown: false,
		name: cutSheetFrame,
	},
	{
		isShown: false,
		name: frameAccessories,
	},
	{
		isShown: false,
		name: cutSheetSash,
	},
	{
		isShown: false,
		name: weldingSheetFrames,
	},
	{
		isShown: false,
		name: sashAccessories,
	},
	{
		isShown: false,
		name: weldingSheetSash,
	},
	{
		isShown: false,
		name: weldingCoil,
	},
	{
		isShown: false,
		name: assembly,
	},
];

export default function SheetsTabs({ orderId, completedOrder }) {
	const classes = useStyles();
	const { selectedWorkstation } = useContext(WorkstationContext);
	const { finishedProcesses, inProcessingProcesses } = useContext(SubProcessCount);
	const [value, setValue] = React.useState(0);
	const [tabs_, setTabs] = React.useState([]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const renderPanel = (name, index) => {
		switch (name) {
			case screen: {
				return (
					<TabPanel value={value} index={index}>
						<ScreenSheet orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case frameAccessories: {
				return (
					<TabPanel value={value} index={index}>
						<FrameAccessories orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case sashAccessories: {
				return (
					<TabPanel value={value} index={index}>
						<SashAccessories orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case cutSheetSash: {
				return (
					<TabPanel value={value} index={index}>
						<CutSheetSash orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case cutSheetFrame: {
				return (
					<TabPanel value={value} index={index}>
						<CutSheetFrame orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case weldingSheetFrames: {
				return (
					<TabPanel value={value} index={index}>
						<WeldingSheetFrames orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case weldingCoil: {
				return (
					<TabPanel value={value} index={index}>
						<Coil orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			case assembly: {
				return (
					<TabPanel value={value} index={index}>
						<Assembly orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
			default: {
				return (
					<TabPanel value={value} index={index}>
						<WeldingSheetSash orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				);
			}
		}
	};

	useEffect(() => {
		const filterTabs = () => {
			if (!completedOrder) {
				const sheet = selectedWorkstation.sheet_data;
				const filteredTabs = [];
				for (let i of tabs) {
					for (let j of sheet) {
						if (i.name === j.name) {
							i.isShown = true;
							i.panel = '';
							filteredTabs.push(i);
						}
					}
				}
				setTabs(filteredTabs);
				return;
			}
			for (let i of tabs) {
				i.isShown = true;
				setTabs((prevState) => [...prevState, i]);
			}
		};
		filterTabs();
	}, [completedOrder, finishedProcesses, inProcessingProcesses, selectedWorkstation]);

	return (
		<div className={classes.root}>
			<Paper square style={{ display: 'inline-block' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					style={{ marginBottom: '-50px', marginLeft: '16px' }}
				>
					{tabs_.map((tab, index) => {
						tab.panel = renderPanel(tab.name, index);
						return <Tab key={index} label={tab.name} className={classes.tab} />;
					})}
					{!completedOrder && (finishedProcesses || inProcessingProcesses) ? (
						<div>
							<div className={classes.itemPStatus}>{inProcessingProcesses} Started </div>
							<div className={classes.itemsFStatus}>{finishedProcesses} Finished</div>
						</div>
					) : null}
				</Tabs>
			</Paper>
			{completedOrder ? (
				<>
					<TabPanel value={value} index={0}>
						<ScreenSheet orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<CutSheetFrame orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<FrameAccessories orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={3}>
						<CutSheetSash orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={4}>
						<WeldingSheetFrames orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={5}>
						<SashAccessories orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={6}>
						<WeldingSheetSash orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={7}>
						<Coil orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
					<TabPanel value={value} index={8}>
						<Assembly orderId={orderId} completedOrder={completedOrder} />
					</TabPanel>
				</>
			) : (
				tabs_.map((tab, index) => {
					return value === index && <span key={index}>{tab?.panel}</span>;
				})
			)}
		</div>
	);
}

export {
	INCREASE_OPTION,
	DECREASE_OPTION,
	screen,
	frameAccessories,
	sashAccessories,
	cutSheetSash,
	cutSheetFrame,
	weldingSheetSash,
	weldingSheetFrames,
	weldingCoil,
};
