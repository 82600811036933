import React, { Suspense, lazy, useEffect, useContext, useReducer } from 'react';
import { createTheme, ThemeProvider, CircularProgress } from '@material-ui/core';
import './App.css';
import { useHistory, HashRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { getToken, getUser } from './app/commonFunctions';
import Appbar from './components/appbar/Appbar';
import PageNotFound from './components/errors/PageNotFound';
import {
	UserContext,
	WorkstationContext,
	PendingOrdersContext,
	PendingOrderDetailsContext,
	CompletedOrdersContext,
	SubProcessCount,
} from './app/context';
import { SET_USER, userReducer, userState } from './app/reducers/user-reducer';
import { workstationReducer, workstationState } from './app/reducers/workstation-reducer';
import { pendingOrdersState, pendingOrderReducer } from './app/reducers/pending-orders-reducer';
import { pendingOrderDetailsReducer, pendingOrderDetailsState } from './app/reducers/pending-orders-details';
import { completedOrdersReducer, completedOrdersState } from './app/reducers/completed-orders-reducer';
import { subProcessesReducer, subProcessesState } from './app/reducers/sub-processes-count';

const Login = lazy(() => import('./pages/login/Login'));
const Home = lazy(() => import('./pages/Home'));
const ErrorHandler = lazy(() => import('./components/errors/ErrorHandler'));

const appTheme = createTheme({
	palette: {
		primary: {
			main: '#63B4FF',
			light: '#91CAFF',
			contrastText: '#F3F9FF',
		},
		secondary: {
			main: '#E2DFDC',
			dark: '#989FAA',
		},
		success: {
			main: '#388E3C',
			light: '#C8E6C9',
		},
		error: {
			main: '#D32F39',
			light: '#FFCDD2',
		},
	},
});

function Routing() {
	const history = useHistory();
	const location = useLocation();
	const { dispatch } = useContext(UserContext);
	useEffect(() => {
		if (!getToken()) {
			history.push('/login');
		} else {
			const user = {
				user: getUser(),
				token: getToken(),
			};
			dispatch({ type: SET_USER, payload: user });
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{getToken() ? (
				<>
					<Appbar />
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<ErrorHandler {...props}>
									<Home {...props} />
								</ErrorHandler>
							)}
						/>
						{location.pathname === '/login' ? <Redirect to="/" /> : <Route component={PageNotFound} />}
					</Switch>
				</>
			) : (
				<Switch>
					<Route path="/login" component={Login} />
					<Redirect to="/login" />
				</Switch>
			)}
		</div>
	);
}

function App() {
	const [user, userDispatch] = useReducer(userReducer, userState);
	const [workstation, workstationDispatch] = useReducer(workstationReducer, workstationState);
	const [orders, ordersDispatch] = useReducer(pendingOrderReducer, pendingOrdersState);
	const [ordersDetails, ordersDetailsDispatch] = useReducer(pendingOrderDetailsReducer, pendingOrderDetailsState);
	const [completedOrders, completedOrdersDispatch] = useReducer(completedOrdersReducer, completedOrdersState);
	const [subProcesses, subProcessesDispatch] = useReducer(subProcessesReducer, subProcessesState);
	return (
		<div className="App">
			<Suspense
				fallback={
					<div style={{ marginTop: '40px' }}>
						<CircularProgress />
					</div>
				}
			>
				<ThemeProvider theme={appTheme}>
					<UserContext.Provider
						value={{
							dispatch: userDispatch,
							user: user.user,
							token: user.token,
						}}
					>
						<SubProcessCount.Provider
							value={{
								dispatch: subProcessesDispatch,
								finishedProcesses: subProcesses.finishedProcesses,
								inProcessingProcesses: subProcesses.inProcessingProcesses,
							}}
						>
							<WorkstationContext.Provider
								value={{
									dispatch: workstationDispatch,
									workstations: workstation.workstations,
									selectedWorkstation: workstation.selectedWorkstation,
								}}
							>
								<PendingOrdersContext.Provider
									value={{
										dispatch: ordersDispatch,
										orders: orders.orders,
									}}
								>
									<PendingOrderDetailsContext.Provider
										value={{
											dispatch: ordersDetailsDispatch,
											orders: ordersDetails.orders,
										}}
									>
										<CompletedOrdersContext.Provider
											value={{
												completedOrders: completedOrders.completedOrders,
												dispatch: completedOrdersDispatch,
											}}
										>
											<HashRouter>
												<Routing />
											</HashRouter>
										</CompletedOrdersContext.Provider>
									</PendingOrderDetailsContext.Provider>
								</PendingOrdersContext.Provider>
							</WorkstationContext.Provider>
						</SubProcessCount.Provider>
					</UserContext.Provider>
				</ThemeProvider>
			</Suspense>
		</div>
	);
}

export default App;
