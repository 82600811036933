import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
	return (
		<Container>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				<Grid item xs={12}>
					<SentimentVeryDissatisfiedIcon style={{ fontSize: '200px' }} />
				</Grid>
				<Grid xs={12}>
					<Typography variant="h3">404</Typography>
				</Grid>
				<Grid xs={12}>
					<Typography variant="h4">Oops! Page not found</Typography>
				</Grid>
				<Grid xs={12} style={{ marginTop: '40px' }}>
					<Link to="/" style={{ fontSize: '23px', textDecoration: 'none', color: '#5cb8f3' }}>
						Back to home page
					</Link>
				</Grid>
			</Grid>
		</Container>
	);
}
