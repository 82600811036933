export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USER = 'GET_USER';

export const userState = {
	user: null,
	token: null,
};

export const userReducer = (state = userState, action) => {
	const type = action.type;
	switch (type) {
		case SET_USER: {
			const user = action.payload.user;
			const token = action.payload.token;
			return {
				user,
				token,
			};
		}
		case REMOVE_USER: {
			return {
				user: null,
				token: null,
			};
		}
		default: {
			return state;
		}
	}
};
