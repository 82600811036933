import { LOGIN, LOGOUT, WORKSTATIONS, PROCESSES, ORDERS, WINDOW_ITEMS, UPDATE_PROCESS } from './APIs';
import axios from 'axios';

export const getConfig = (token) => {
	return {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: `bearer ${token}`,
		},
	};
};

export const login = async (data) => {
	try {
		const res = await axios.post(LOGIN, data, getConfig(''));
		return res.data;
	} catch (error) {
		return error.response?.status;
	}
};

export const logout = async (token) => {
	try {
		await axios.get(LOGOUT, getConfig(token));
		return 200;
	} catch (error) {
		return null;
	}
};

export const getWorkStations = async (token) => {
	try {
		const res = await axios.get(WORKSTATIONS, getConfig(token));
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};

export const getProcesses = async (workstationId, token) => {
	try {
		const res = await axios.get(`${PROCESSES}/${workstationId}/get_processes/`, getConfig(token));
		const filterByOrder = res.data?.reduce((Order_list, { id, process_status, Process, SheetProcesses }) => {
			if (!Order_list[Process.window_item.order]) Order_list[Process.window_item.order] = [];
			const ProcessWindowItem = Process.window_item;
			const OrderID = Process.window_item.order;
			const cut_sheet = ProcessWindowItem.cut_sheet;
			const welding_sheet = ProcessWindowItem.welding_sheet;
			const screen_sheet = ProcessWindowItem.screen_sheet;
			const sheetProcesses = SheetProcesses;
			Order_list[Process.window_item.order].push({
				OrderID,
				id,
				process_status,
				ProcessWindowItem,
				cut_sheet,
				welding_sheet,
				screen_sheet,
				sheetProcesses,
			});
			return Order_list;
		}, {});
		return {
			data: filterByOrder,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};

export const getOrders = async (orderId, token) => {
	try {
		const res = await axios.get(`${ORDERS}/${orderId}`, getConfig(token));
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};

export const getFinishedOrders = async (workstationId, page, token) => {
	try {
		const res = await axios.get(
			`${PROCESSES}/${workstationId}/get_completed_orders/?page=${page}&page_size=${10}`,
			getConfig(token)
		);
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};

export const getWindowItems = async (orderId, token) => {
	try {
		const res = await axios.get(`${WINDOW_ITEMS}/${orderId}/get_by_order/`, getConfig(token));
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};

export const updateProcessStatus = async (data, parentProcessId, token) => {
	try {
		const res = await axios.put(`${UPDATE_PROCESS}/${parentProcessId}/`, data, getConfig(token));
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};

export const updateParentProcessStatus = async (id, process_status, token) => {
	try {
		const data = {
			id,
			process_status,
		};
		const res = await axios.put(`${PROCESSES}/${id}/`, data, getConfig(token));
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		return {
			data: null,
			code: error.response?.status,
		};
	}
};
