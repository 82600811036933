import React, { useEffect, useContext } from 'react';
import { makeStyles, useTheme, AppBar, Toolbar, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StyledMenuComponent from './StyledMenu';
import { UserContext } from '../../app/context';
import UserInfo from '../drop-down/UserInfo';
import WorkStations from '../drop-down/WorkStations';

const dateFormat = require('dateformat');

const useStyles = makeStyles((theme) => ({
	headerOptions: {
		display: 'flex',
		background: 'green',
		justifyContent: 'space-around',
	},
	headerItem: {
		margin: '5px',
		borderRadius: '3px',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		cursor: 'pointer',
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginRight: '5px',
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	appbarColor: {
		background: theme.palette.common.white,
	},
	root: {
		marginBottom: '40px',
		position: 'sticky',
	},
	toolbar: theme.mixins.toolbar,
	logout: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.common.white,
		minWidth: '75px',
	},
	cursor: {
		cursor: 'pointer',
		'&:hover': {
			color: 'white',
		},
	},
	bg: {
		background: theme.palette.secondary.main,
		fontWeight: 'bold',
		padding: '13px 0px',
		borderRadius: '3px',
		fontSize: '2.6vh',
		[theme.breakpoints.down('md')]: {
			marginLeft: '1%',
		},
		marginRight: '1%',
		color: 'black',
	},
	login: {
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}));

export default function Appbar() {
	const classes = useStyles();
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
	const { token } = useContext(UserContext);

	useEffect(() => {}, [token]);

	const navbar = () => {
		return (
			<>
				{isTablet ? (
					<StyledMenuComponent />
				) : (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						style={{ padding: '10px 0px' }}
					>
						<Grid item xs={5}>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<Grid item xs={4}>
									<Typography variant="h6" className={classes.bg}>
										Workstation ID:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<WorkStations />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={3}>
							<Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
								{dateFormat(new Date(), 'ddd mmm dd yyyy')}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<UserInfo />
						</Grid>
					</Grid>
				)}
			</>
		);
	};

	return (
		<div className={classes.toolbar}>
			<AppBar position="static" className={classes.appbarColor}>
				<Toolbar>{navbar()}</Toolbar>
			</AppBar>
		</div>
	);
}
