import React, { useContext, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button } from '@material-ui/core';
import { UserContext } from '../../app/context';
import Backdrop from '../backdrop/Backdrop';
import Alert from '../alert/Alert';
import { logout } from '../../app/server/services';
import { removeUser } from '../../app/commonFunctions';
import { REMOVE_USER } from '../../app/reducers/user-reducer';
import { useHistory } from 'react-router';

export default function UserInfo() {
	const [anchorEl, setAnchorEl] = useState(null);
	const { user, token, dispatch } = useContext(UserContext);
	const [backdrop, setBackdrop] = useState(false);
	const [alert, setAlert] = useState({
		status: false,
		message: '',
	});
	const history = useHistory();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLogout = async () => {
		handleClose();
		setBackdrop(true);
		const res = await logout(token);
		setBackdrop(false);
		if (res) {
			removeUser();
			dispatch({ type: REMOVE_USER });
			history.push('/login');
			return;
		}
		setAlert({
			status: true,
			message: 'Something wrong happened!',
		});
	};

	return (
		<div>
			{backdrop ? <Backdrop /> : null}
			{alert.status ? <Alert message={alert.message} setOpen={setAlert} /> : null}
			<Button
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
				variant="outlined"
				color="primary"
				style={{ fontWeight: 'bold', fontSize: '2.4vh' }}
				endIcon={<ArrowDropDownIcon />}
			>
				{user?.username}
			</Button>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				PaperProps={{
					style: {
						width: '23ch',
						marginTop: '47px',
					},
				}}
			>
				<MenuItem>
					<ListItemIcon style={{ marginRight: '-25px' }}>
						<AccountBoxIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary={user?.username} />
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<ListItemIcon style={{ marginRight: '-25px' }}>
						<ExitToAppIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</MenuItem>
			</Menu>
		</div>
	);
}
