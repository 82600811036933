export const SET_ORDERS = 'SET_ORDERS';

export const pendingOrdersState = {
	orders: [],
};

export const pendingOrderReducer = (state = pendingOrdersState, action) => {
	const type = action.type;
	switch (type) {
		case SET_ORDERS: {
			return {
				orders: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};
