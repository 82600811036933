import React from 'react';
import { removeUser } from '../commonFunctions';
import { UserContext } from '../context';
import { REMOVE_USER } from '../reducers/user-reducer';
import { useHistory } from 'react-router-dom';

export default function LogoutHook() {
	const { dispatch } = React.useContext(UserContext);
	const history = useHistory();
	const handleLogout = () => {
		removeUser();
		dispatch({ type: REMOVE_USER });
		history.push('/login');
	};
	return { handleLogout };
}
