const ROOT = process.env.REACT_APP_API_ROOT;
const BASE_URL = `${ROOT}/api`;

const LOGIN = `${BASE_URL}/auth/login/`;
const LOGOUT = `${BASE_URL}/workstations/logout/`;
const WORKSTATIONS = `${BASE_URL}/workstations/get_workstation/`;
const PROCESSES = `${BASE_URL}/workstation_processes`;
const ORDERS = `${BASE_URL}/orders`;
const WINDOW_ITEMS = `${BASE_URL}/window_Items`;
const UPDATE_PROCESS = `${BASE_URL}/sheet_proceses_status`;

export { ROOT, LOGIN, LOGOUT, WORKSTATIONS, PROCESSES, ORDERS, WINDOW_ITEMS, UPDATE_PROCESS };
